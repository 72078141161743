import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile } from '../../../redux/actions/file';
import { useHistory, useParams } from 'react-router-dom';
import { createDocument, deleteDocument, getDocumentsList, regenerateDigitalProtocol } from '../../../redux/actions/documents';
import { selectIsAdmin } from '../../../redux/selectors/auth';

const Documents = (props) => {
  const [ready, setReady] = useState(false);
  const dispatch = useDispatch();
  const isAdmin = useSelector(selectIsAdmin);
  const applicationId = useParams().id;
  const { documents } = useSelector((state) => state);
  const { fullList } = useSelector((state) => state);
  const history = useHistory();
  const [doc, setDoc] = useState([]);

  useEffect(() => {
    // documents.forEach(element => {
    //     setDoc((doc)=> ({...doc,[element.type]:element}));
    //   });
    props.fetchDocuments();
    documents.forEach((element) => {
      props.setDoc(() => ({ ...props.doc, [element.type]: element }));
    });
  }, [doc]);
  // const printClick = () => {
  //   history.push(`/print/${applicationId}/podan`);
  // };

  const regenerateDigitalProtocolClick=()=>{
    let data={
      'applicationId':applicationId,
    }
    dispatch(regenerateDigitalProtocol(data)).then(res=>{
      
    })
  }
  // SubmissionSubjectApplication = 0;
  // CrimeaSubmissionSubjectApplication = 1;
  // SportFederationConclusion = 2;
  // PassportPage = 3;
  // PassPermission = 4;
  // AthletePhoto = 5;
  // Diploma = 6;
  // Workbook = 7;
  // TrainerDocument = 8;
  // TrainerWorkplaceConfirmation = 9;
  // RewardOrder = 10;
  // TournamentConfirmation = 11;
  // SportGatheringConfirmation = 12;
  // ParticipantApplication = 13;
  const onDocumentUpload = useCallback(async (event) => {
    if (event.target.files[0]) {
      await dispatch(uploadFile(event.target.files[0]))
        .then((response) => {
          let data = {
            applicationId: applicationId,
            type: event.target.name,
            name: event.target.files[0].name,
            url: response,
          };
          dispatch(createDocument(data))
            .then((response) => {
              props.fetchDocuments();
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  const deleteHandler = useCallback(async (event) => {
    event.preventDefault();
    let data = {
      applicationId: applicationId,
      id: event.target.parentElement.id,
    };
    await dispatch(deleteDocument(data))
      .then(() => {
        props.fetchDocuments();
      })
      .catch(() => {});
  }, []);

  if (props.ready) {
    return (
      <div className="create-application-container">
        <h2>Для присвоєння звання «{props.form.athleteCategoryTitle}»</h2>
        {isAdmin && (
          <label className="create-label-doc">
            <span></span>
            {(
              <button
                className="icon-text-btn-secondary print"
                style={{ width: '220px', margin: '20px 0 0 0' }}
                onClick={regenerateDigitalProtocolClick}
              >
                Перегенерувати&nbsp;протокол
              </button>
            )}
          </label>
        )}

        {props.doc[19] && (
          <>
            <label className="create-label-doc">
              <span>Висновок для комісії </span>
              <a
                target="_blank"
                href={props.form && props.doc[19] && props.doc[19].url && props.doc[19].url}
                id={props.doc[19].id}
                className="document-link"
              >
                {props.doc[19].name}
              </a>
            </label>
           {props.doc[20] && (
            <>
            <label className="create-label-doc">
              <span>Підпис висновку для комісії</span>
              <a
                target="_blank"
                href={props.form && props.doc[20] && props.doc[20].url && props.doc[20].url}
                id={props.doc[20].id}
                className="document-link"
              >
                {props.doc[20].name}
              </a>
            </label>
            </>)}
          </>)}

          {props.doc[21] && (
          <>
            <label className="create-label-doc">
              <span>Висновок директора </span>
              <a
                target="_blank"
                href={props.form && props.doc[21] && props.doc[21].url && props.doc[21].url}
                id={props.doc[21].id}
                className="document-link"
              >
                {props.doc[21].name}
              </a>
            </label>
           {props.doc[22] && (
            <>
            <label className="create-label-doc">
              <span>Підпис до висновку директора</span>
              <a
                target="_blank"
                href={props.form && props.doc[22] && props.doc[22].url && props.doc[22].url}
                id={props.doc[22].id}
                className="document-link"
              >
                {props.doc[22].name}
              </a>
            </label>
            </>)}
          </>)}
        {props.doc[16] && (
          <>
            <label className="create-label-doc">
              <span>Заявка в електронному вигляді</span>
              <a
                target="_blank"
                href={props.form && props.doc[16] && props.doc[16].url && props.doc[16].url}
                id={props.doc[16].id}
                className="document-link"
              >
                {props.doc[16].name}
              </a>
            </label>
            {props.doc[17] && (
              <label className="create-label-doc">
                <span>Протокол підписання</span>
                <a
                  target="_blank"
                  href={
                    props.form &&
                    props.doc[16] &&
                    props.doc[16].url &&
                    props.doc[16].url.replace('app-', 'protocol-')
                  }
                  id={props.doc[16].id}
                  className="document-link"
                >
                  {props.doc[16].name.replace('app-', 'protocol-')}
                </a>
              </label>
            )}
          </>
        )}

        {/* {(props.form.officeCode || props.org === 1 || props.org === 2) && (
          <label className="create-label-doc">
            <span>
              Клопотання структурного підрозділу з фізичної культури та спорту Міністерства освіти і
              науки, молоді та спорту Автономної Республіки Крим, обласних, Київської та
              Севастопольської міських державних адміністрацій
            </span>
            {!props.doc[0] && props.form.status === 2 && props.org === 1 && (
              <label className="icon-text-btn-secondary dow" htmlFor="0">
                Завантажити документ
                <div className="document-type-hint">
                  Допустимі формати файлів ".pdf", ".jpg", ".jpeg", ".png"
                </div>
              </label>
            )}
            {!props.doc[0] && (
              <input
                accept="application/pdf,.jpg,.jpeg,.png"
                type="file"
                id="0"
                name="0"
                className="documentInput"
                onChange={onDocumentUpload}
              />
            )}
            {props.doc && props.doc[0] && props.doc[0].url && (
              <a
                target="_blank"
                href={props.doc && props.doc[0] && props.doc[0].url && props.doc[0].url}
                id={props.doc[0].id}
                className="document-link"
              >
                {props.doc[0].name}
                {!props.form.officeCode && props.org === 1 && props.form.status === 2 && (
                  <i className="delete-icon doc-icon" onClick={deleteHandler}></i>
                )}
              </a>
            )}
          </label>
        )} */}

        {/* <label className='create-label-doc' style={{'color':`${props.error.doc && !props.doc[1] ?'red':''}`}}>
                <span>Клопотання структурного підрозділу з фізичної культури та спорту Міністерства освіти і науки, молоді та спорту Автономної Республіки Крим, обласних, Київської та Севастопольської міських державних адміністрацій</span>
                {!props.doc[1] && ((props.permission!==0 &&props.permission!==1 && props.form.officeCode) || props.permission!==2)&&props.org===0&& <label className='icon-text-btn-secondary dow' htmlFor="1">Завантажити документ</label>}
                {!props.doc[1] && <input accept="doc,.docx,.xml,application/msword,application/vnd.openxmlprops.formats-officedocument.wordprocessingml.document,
text/plain, application/pdf" type="file" id="1" name="1" className="documentInput" onChange={onDocumentUpload}/>}
                {props.doc && props.doc[1] && props.doc[1].url && <a href={props.doc && props.doc[1] && props.doc[1].url &&props.doc[1].url} id={props.doc[1].id} className='document-link'>{props.doc[1].name}{(props.permission===0 || props.permission==1 )&&props.org===0&& <i className='delete-icon doc-icon' onClick={deleteHandler}></i>}</a>}
            </label> */}
        <label
          className="create-label-doc"
          style={{ color: `${props.error.doc && !props.doc[11] ? 'red' : ''}` }}
        >
          <span>
            Витяги з протоколів офіційних змагань (для ігрових видів спорту – копії протоколів
            змагань, що засвідчують участь спортсмена не менше ніж у 50 відсотках ігор за календарем
            змагань) за підписом уповноваженої посадової особи всеукраїнської спортивної федерації з
            відповідного виду спорту (спортивної федерації інвалідів), завірені печаткою цієї
            організації *
          </span>
          {!props.doc[11] &&
            ((props.permission !== 0 && props.permission !== 1 && props.form.officeCode) ||
              props.permission !== 2) &&
            props.org === 0 && (
              <label className="icon-text-btn-secondary dow" htmlFor="11">
                Завантажити документ
                <div className="document-type-hint">
                  Допустимі формати файлів ".pdf", ".jpg", ".jpeg", ".png"
                </div>
              </label>
            )}
          {!props.doc[11] && (
            <input
              accept="application/pdf,.jpg,.jpeg,.png"
              type="file"
              id="11"
              name="11"
              className="documentInput"
              onChange={onDocumentUpload}
            />
          )}
          {props.form && props.doc[11] && props.doc[11].url && (
            <a
              target="_blank"
              href={props.form && props.doc[11] && props.doc[11].url && props.doc[11].url}
              id={props.doc[11].id}
              className="document-link"
            >
              {props.doc[11].name}
              {(props.permission === 0 || (props.permission == 1)) &&
                props.org === 0 && <i className="delete-icon doc-icon" onClick={deleteHandler}></i>}
            </a>
          )}
        </label>
        <label
          className="create-label-doc"
          style={{ color: `${props.error.doc && !props.doc[3] ? 'red' : ''}` }}
        >
          <span>Копія 1-ї та 2-ї сторінки паспорту громадянина України { props.form.codeType == '1'?'та копію РНОКПП':''} *</span>
          {!props.doc[3] &&
            ((props.permission !== 0 && props.permission !== 1 && props.form.officeCode) ||
              props.permission !== 2) &&
            props.org === 0 && (
              <label className="icon-text-btn-secondary dow" htmlFor="3">
                Завантажити документ
                <div className="document-type-hint">
                  Допустимі формати файлів ".pdf", ".jpg", ".jpeg", ".png"
                </div>
              </label>
            )}
          {!props.doc[3] && (
            <input
              accept="application/pdf,.jpg,.jpeg,.png"
              type="file"
              id="3"
              name="3"
              className="documentInput"
              onChange={onDocumentUpload}
            />
          )}
          {props.form && props.doc[3] && props.doc[3].url && (
            <a
              target="_blank"
              href={props.form && props.doc[3] && props.doc[3].url && props.doc[3].url}
              id={props.doc[3].id}
              className="document-link"
            >
              {props.doc[3].name}
              {(props.permission === 0 || props.permission == 1 ) &&
                props.org === 0 && <i className="delete-icon doc-icon" onClick={deleteHandler}></i>}
            </a>
          )}
        </label>

        {(props.doc[4] ||
          (props.org === 0 && (props.permission == 0 || props.permission == 1))) && (
          <label className="create-label-doc">
            <span>
              Копія документа, що дає право перебувати в Україні на законних підставах, завірена за
              місцем роботи або структурним підрозділом з фізичної культури та спорту Міністерства
              освіти і науки, молоді та спорту Автономної Республіки Крим, обласних, Київської та
              Севастопольської міських державних адміністрацій – для спортсменів-іноземців, осіб без
              громадянства
            </span>
            {!props.doc[4] &&
              ((props.permission !== 0 && props.permission !== 1 && props.form.officeCode) ||
                props.permission !== 2) &&
              props.org === 0 && (
                <label className="icon-text-btn-secondary dow" htmlFor="4">
                  Завантажити документ
                  <div className="document-type-hint">
                    Допустимі формати файлів ".pdf", ".jpg", ".jpeg", ".png"
                  </div>
                </label>
              )}
            {!props.doc[4] && (
              <input
                accept="application/pdf,.jpg,.jpeg,.png"
                type="file"
                id="4"
                name="4"
                className="documentInput"
                onChange={onDocumentUpload}
              />
            )}
            {props.form && props.doc[4] && props.doc[4].url && (
              <a
                target="_blank"
                href={props.form && props.doc[4] && props.doc[4].url && props.doc[4].url}
                id={props.doc[4].id}
                className="document-link"
              >
                {props.doc[4].name}
                {(props.permission === 0 ||props.permission == 1 ) &&
                  props.org === 0 && (
                    <i className="delete-icon doc-icon" onClick={deleteHandler}></i>
                  )}
              </a>
            )}
          </label>
        )}

        {props.form &&
          fullList[2] &&
          fullList[2][1] &&
          props.form.athleteCategoryTitle ==
            fullList[2][1].title + '/' + fullList[2][1].description && (
            <>
              <label
                className="create-label-doc"
                style={{ color: `${props.error.doc2 && !props.doc[2] ? 'red' : ''}` }}
              >
                <span>
                  Висновок всеукраїнської спортивної федерації з відповідного виду спорту
                  (спортивної федерації видів спорту осіб з інвалідністю за нозологією), який
                  оформляється на бланку цієї організації за підписом уповноваженої посадової особи
                  та завірений печаткою цієї організації
                </span>
                {!props.doc[2] &&
                  ((props.permission !== 0 && props.permission !== 1 && props.form.officeCode) ||
                    props.permission !== 2) &&
                  props.org === 0 && (
                    <label className="icon-text-btn-secondary dow" htmlFor="2">
                      Завантажити документ
                      <div className="document-type-hint">
                        Допустимі формати файлів ".pdf", ".jpg", ".jpeg", ".png"
                      </div>
                    </label>
                  )}
                {!props.doc[2] && (
                  <input
                    accept="application/pdf,.jpg,.jpeg,.png"
                    type="file"
                    id="2"
                    name="2"
                    className="documentInput"
                    onChange={onDocumentUpload}
                  />
                )}
                {props.form && props.doc[2] && props.doc[2].url && (
                  <a
                    target="_blank"
                    href={props.form && props.doc[2] && props.doc[2].url && props.doc[2].url}
                    id={props.doc[2].id}
                    className="document-link"
                  >
                    {props.doc[2].name}
                    {(props.permission === 0 ||
                      props.permission == 1 ) &&
                      props.org === 0 && (
                        <i className="delete-icon doc-icon" onClick={deleteHandler}></i>
                      )}
                  </a>
                )}
              </label>
              <label
                className="create-label-doc"
                style={{ color: `${props.error.doc2 && !props.doc[6] ? 'red' : ''}` }}
              >
                <span>Копія диплома, завірена за місцем роботи *</span>
                {!props.doc[6] &&
                  ((props.permission !== 0 && props.permission !== 1 && props.form.officeCode) ||
                    props.permission !== 2) &&
                  props.org === 0 && (
                    <label className="icon-text-btn-secondary dow" htmlFor="6">
                      Завантажити документ
                      <div className="document-type-hint">
                        Допустимі формати файлів ".pdf", ".jpg", ".jpeg", ".png"
                      </div>
                    </label>
                  )}
                {!props.doc[6] && (
                  <input
                    accept="application/pdf,.jpg,.jpeg,.png"
                    type="file"
                    id="6"
                    name="6"
                    className="documentInput"
                    onChange={onDocumentUpload}
                  />
                )}
                {props.form && props.doc[6] && props.doc[6].url && (
                  <a
                    target="_blank"
                    href={props.form && props.doc[6] && props.doc[6].url && props.doc[6].url}
                    id={props.doc[6].id}
                    className="document-link"
                  >
                    {props.doc[6].name}
                    {(props.permission === 0 ||
                      props.permission == 1 ) &&
                      props.org === 0 && (
                        <i className="delete-icon doc-icon" onClick={deleteHandler}></i>
                      )}
                  </a>
                )}
              </label>
              <label
                className="create-label-doc"
                style={{ color: `${props.error.doc2 && !props.doc[7] ? 'red' : ''}` }}
              >
                <span>Копія трудової книжки, завірена за місцем роботи *</span>
                {!props.doc[7] &&
                  ((props.permission !== 0 && props.permission !== 1 && props.form.officeCode) ||
                    props.permission !== 2) &&
                  props.org === 0 && (
                    <label className="icon-text-btn-secondary dow" htmlFor="7">
                      Завантажити документ
                      <div className="document-type-hint">
                        Допустимі формати файлів ".pdf", ".jpg", ".jpeg", ".png"
                      </div>
                    </label>
                  )}
                {!props.doc[7] && (
                  <input
                    accept="application/pdf,.jpg,.jpeg,.png"
                    type="file"
                    id="7"
                    name="7"
                    className="documentInput"
                    onChange={onDocumentUpload}
                  />
                )}
                {props.form && props.doc[7] && props.doc[7].url && (
                  <a
                    target="_blank"
                    href={props.form && props.doc[7] && props.doc[7].url && props.doc[7].url}
                    id={props.doc[7].id}
                    className="document-link"
                  >
                    {props.doc[7].name}
                    {(props.permission === 0 ||
                      props.permission == 1 ) &&
                      props.org === 0 && (
                        <i className="delete-icon doc-icon" onClick={deleteHandler}></i>
                      )}
                  </a>
                )}
              </label>
              <label
                className="create-label-doc"
                style={{ color: `${props.error.doc2 && !props.doc[9] ? 'red' : ''}` }}
              >
                <span>
                  Виписка з журналу обліку роботи тренера (тренера-викладача), що засвідчується
                  підписом керівника та печаткою закладу фізичної культури і спорту *
                </span>
                {!props.doc[9] &&
                  ((props.permission !== 0 && props.permission !== 1 && props.form.officeCode) ||
                    props.permission !== 2) &&
                  props.org === 0 && (
                    <label className="icon-text-btn-secondary dow" htmlFor="9">
                      Завантажити документ
                      <div className="document-type-hint">
                        Допустимі формати файлів ".pdf", ".jpg", ".jpeg", ".png"
                      </div>
                    </label>
                  )}
                {!props.doc[9] && (
                  <input
                    accept="application/pdf,.jpg,.jpeg,.png"
                    type="file"
                    id="9"
                    name="9"
                    className="documentInput"
                    onChange={onDocumentUpload}
                  />
                )}
                {props.form && props.doc[9] && props.doc[9].url && (
                  <a
                    target="_blank"
                    href={props.form && props.doc[9] && props.doc[9].url && props.doc[9].url}
                    id={props.doc[9].id}
                    className="document-link"
                  >
                    {props.doc[9].name}
                    {(props.permission === 0 ||
                      props.permission == 1 ) &&
                      props.org === 0 && (
                        <i className="delete-icon doc-icon" onClick={deleteHandler}></i>
                      )}
                  </a>
                )}
              </label>
              <label
                className="create-label-doc"
                style={{ color: `${props.error.doc2 && !props.doc[8] ? 'red' : ''}` }}
              >
                <span>
                  Особиста картка тренера (тренера-викладача), завірена за місцем роботи.*
                </span>
                {!props.doc[8] &&
                  ((props.permission !== 0 && props.permission !== 1 && props.form.officeCode) ||
                    props.permission !== 2) &&
                  props.org === 0 && (
                    <label className="icon-text-btn-secondary dow" htmlFor="8">
                      Завантажити документ
                      <div className="document-type-hint">
                        Допустимі формати файлів ".pdf", ".jpg", ".jpeg", ".png"
                      </div>
                    </label>
                  )}
                {!props.doc[8] && (
                  <input
                    accept="application/pdf,.jpg,.jpeg,.png"
                    type="file"
                    id="8"
                    name="8"
                    className="documentInput"
                    onChange={onDocumentUpload}
                  />
                )}
                {props.form && props.doc[8] && props.doc[8].url && (
                  <a
                    target="_blank"
                    href={props.form && props.doc[8] && props.doc[8].url && props.doc[8].url}
                    id={props.doc[8].id}
                    className="document-link"
                  >
                    {props.doc[8].name}
                    {(props.permission === 0 ||
                      props.permission == 1 ) &&
                      props.org === 0 && (
                        <i className="delete-icon doc-icon" onClick={deleteHandler}></i>
                      )}
                  </a>
                )}
              </label>
              <label
                className="create-label-doc"
                style={{ color: `${props.error.doc2 && !props.doc[14] ? 'red' : ''}` }}
              >
                <span>
                  Довідка з місця роботи, що підтверджує стаж роботи тренера (тренера-викладача) зі
                  спортсменом, за підписом керівника закладу фізичної культури і спорту. *
                </span>
                {!props.doc[14] &&
                  ((props.permission !== 0 && props.permission !== 1 && props.form.officeCode) ||
                    props.permission !== 2) &&
                  props.org === 0 && (
                    <label className="icon-text-btn-secondary dow" htmlFor="14">
                      Завантажити документ
                      <div className="document-type-hint">
                        Допустимі формати файлів ".pdf", ".jpg", ".jpeg", ".png"
                      </div>
                    </label>
                  )}
                {!props.doc[14] && (
                  <input
                    accept="application/pdf,.jpg,.jpeg,.png"
                    type="file"
                    id="14"
                    name="14"
                    className="documentInput"
                    onChange={onDocumentUpload}
                  />
                )}
                {props.form && props.doc[14] && props.doc[14].url && (
                  <a
                    target="_blank"
                    href={props.form && props.doc[14] && props.doc[14].url && props.doc[14].url}
                    id={props.doc[14].id}
                    className="document-link"
                  >
                    {props.doc[14].name}
                    {(props.permission === 0 ||
                      props.permission == 1 ) &&
                      props.org === 0 && (
                        <i className="delete-icon doc-icon" onClick={deleteHandler}></i>
                      )}
                  </a>
                )}
              </label>
              <label
                className="create-label-doc"
                style={{ color: `${props.error.doc2 && !props.doc[18] ? 'red' : ''}` }}
              >
                <span>
                 Заява спортсмена.*
                </span>
                {!props.doc[18] &&
                  ((props.permission !== 0 && props.permission !== 1 && props.form.officeCode) ||
                    props.permission !== 2) &&
                  props.org === 0 && (
                    <label className="icon-text-btn-secondary dow" htmlFor="18">
                      Завантажити документ
                      <div className="document-type-hint">
                        Допустимі формати файлів ".pdf", ".jpg", ".jpeg", ".png"
                      </div>
                    </label>
                  )}
                {!props.doc[18] && (
                  <input
                    accept="application/pdf,.jpg,.jpeg,.png"
                    type="file"
                    id="18"
                    name="18"
                    className="documentInput"
                    onChange={onDocumentUpload}
                  />
                )}
                {props.form && props.doc[18] && props.doc[18].url && (
                  <a
                    target="_blank"
                    href={props.form && props.doc[18] && props.doc[18].url && props.doc[18].url}
                    id={props.doc[18].id}
                    className="document-link"
                  >
                    {props.doc[18].name}
                    {(props.permission === 0 ||
                      props.permission == 1) &&
                      props.org === 0 && (
                        <i className="delete-icon doc-icon" onClick={deleteHandler}></i>
                      )}
                  </a>
                )}
              </label>
              <label
                className="create-label-doc"
                // style={{ color: `${props.error.doc2 && !props.doc[10] ? 'red' : ''}` }}
              >
                <span>
                  Витяг з наказу про присвоєння спортивного розряду спортсмену, що засвідчується
                  підписом керівника та печаткою закладу фізичної культури і спорту – для першого
                  тренера спортсмена (за необхідністю)
                </span>
                {!props.doc[10] &&
                  ((props.permission !== 0 && props.permission !== 1 && props.form.officeCode) ||
                    props.permission !== 2) &&
                  props.org === 0 && (
                    <label className="icon-text-btn-secondary dow" htmlFor="10">
                      Завантажити документ
                      <div className="document-type-hint">
                        Допустимі формати файлів ".pdf", ".jpg", ".jpeg", ".png"
                      </div>
                    </label>
                  )}
                {!props.doc[10] && (
                  <input
                    accept="application/pdf,.jpg,.jpeg,.png"
                    type="file"
                    id="10"
                    name="10"
                    className="documentInput"
                    onChange={onDocumentUpload}
                  />
                )}
                {props.form && props.doc[10] && props.doc[10].url && (
                  <a
                    target="_blank"
                    href={props.form && props.doc[10] && props.doc[10].url && props.doc[10].url}
                    id={props.doc[10].id}
                    className="document-link"
                  >
                    {props.doc[10].name}
                    {(props.permission === 0 ||
                      props.permission == 1) &&
                      props.org === 0 && (
                        <i className="delete-icon doc-icon" onClick={deleteHandler}></i>
                      )}
                  </a>
                )}
              </label>
              <label
                className="create-label-doc"
                // style={{ color: `${props.error.doc2 && !props.doc[13] ? 'red' : ''}` }}
              >
                <span>
                  Наказ Міністерства молоді та спорту України про участь офіційної делегації
                  національної збірної команди України" (за необхідністю)
                </span>
                {!props.doc[13] &&
                  ((props.permission !== 0 && props.permission !== 1 && props.form.officeCode) ||
                    props.permission !== 2) &&
                  props.org === 0 && (
                    <label className="icon-text-btn-secondary dow" htmlFor="13">
                      Завантажити документ
                      <div className="document-type-hint">
                        Допустимі формати файлів ".pdf", ".jpg", ".jpeg", ".png"
                      </div>
                    </label>
                  )}
                {!props.doc[13] && (
                  <input
                    accept="application/pdf,.jpg,.jpeg,.png"
                    type="file"
                    id="13"
                    name="13"
                    className="documentInput"
                    onChange={onDocumentUpload}
                  />
                )}
                {props.form && props.doc[13] && props.doc[13].url && (
                  <a
                    target="_blank"
                    href={props.form && props.doc[13] && props.doc[13].url && props.doc[13].url}
                    id={props.doc[13].id}
                    className="document-link"
                  >
                    {props.doc[13].name}
                    {(props.permission === 0 ||
                      props.permission == 1 ) &&
                      props.org === 0 && (
                        <i className="delete-icon doc-icon" onClick={deleteHandler}></i>
                      )}
                  </a>
                )}
              </label>
             
              {
                <label className="create-label-doc">
                  <span>Виписка з рішення загальних зборів бригади (у разі потреби)</span>
                  {!props.doc[12] &&
                    ((props.permission !== 0 && props.permission !== 1 && props.form.officeCode) ||
                      props.permission !== 2) &&
                    props.org === 0 && (
                      <label className="icon-text-btn-secondary dow" htmlFor="12">
                        Завантажити документ
                        <div className="document-type-hint">
                          Допустимі формати файлів ".pdf", ".jpg", ".jpeg", ".png"
                        </div>
                      </label>
                    )}
                  {!props.doc[12] && (
                    <input
                      accept="application/pdf,.jpg,.jpeg,.png"
                      type="file"
                      id="12"
                      name="12"
                      className="documentInput"
                      onChange={onDocumentUpload}
                    />
                  )}
                  {props.form && props.doc[12] && props.doc[12].url && (
                    <a
                      target="_blank"
                      href={props.form && props.doc[12] && props.doc[12].url && props.doc[12].url}
                      id={props.doc[12].id}
                      className="document-link"
                    >
                      {props.doc[12].name}
                      {(props.permission === 0 ||
                        props.permission == 1) &&
                        props.org === 0 && (
                          <i className="delete-icon doc-icon" onClick={deleteHandler}></i>
                        )}
                    </a>
                  )}
                </label>
              }
            </>
          )}
      </div>
    );
  } else {
    return <div>Loading</div>;
  }
};

export default Documents;
