import React from 'react';

class Res extends React.Component {
  render() {
    if (
      document.getElementsByClassName('sidebar') &&
      document.getElementsByClassName('sidebar')[0] &&
      document.getElementsByClassName('sidebar')[0].hidden !== true
    ) {
      document.getElementsByClassName('sidebar')[0].hidden = true;
    }
    let inva ='';
    const checkInvaFunc=(id)=>{
      if(id==='571d330c-6be2-43ec-99a2-37392ca27430'){
        inva='(з ураженнями ОРА)';
      }else if(id==='75fe8551-3d90-49ad-83e4-aea858950264'){
        inva='(з порушеннями зору)';
      }else if(id==='e6823e81-e1dc-45c9-9805-53d8aa77580d'){
        inva='(з порушеннями РФР)';
      }else if(id==='ec246cb5-c296-497f-a4c2-f86d15fb9faa'){
        inva='(з порушеннями слуху)'
      }
      return inva;
    }

    const getPreposition = (sportTitleGenitive) => {
      if (!sportTitleGenitive || sportTitleGenitive.length < 2) {
        return 'з'; // Випадок, коли значення не відповідає умовам
      }
    
      const firstTwoLetters = sportTitleGenitive.slice(0, 2).toLowerCase();
      const consonants = ['з', 'с', 'ц', 'ч', 'ш', 'щ'];
      
      const hasRequiredConsonant = firstTwoLetters?.split('').some(letter => consonants.includes(letter));
      const bothConsonants = firstTwoLetters?.split('').every(letter => /[бвгґджзйклмнпрстфхцчшщ]/.test(letter));
    
      return hasRequiredConsonant && bothConsonants ? 'зі' : 'з';
    };

    
    return (
      <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '107mm' }}>
        {this.props.certificate &&
          this.props.certificate.list.map((el, index) => {
            if (
              (el.application.athleteCategoryTitleId === '391fcc05-5ef6-4f49-a432-e4f3dd6c1432' ||
              el.application.athleteCategoryTitleId === 'f67f6f82-0d44-43b6-847c-fb1be013e3c9') ||
             ( el.application.athleteCategoryTitle==='Майстер спорту України міжнародного класу/МСУМК' || el.application.athleteCategoryTitle==='Майстер спорту України/МСУ')
            ) {
              return (
                <div
                  key={el.application.id}
                  style={
                    (index + 1) % 3 === 0
                      ? { margin: '20px auto 0', height: '70mm', pageBreakAfter: 'always' }
                      : { margin: '20px auto 0', height: '70mm' }
                  }
                >
                  <div
                    style={{
                      width: '107mm',
                      height: '70mm',
                      background: 'URL("/Res/bluemarble.png")',
                    }}
                  >
                    <table
                      width="100%"
                      height="100%"
                      cellspacing="0"
                      cellpadding="0"
                      border="0"
                      style={{
                        lineHeight: '1',
                        fontSize: '1mm',
                        fontFamily: '"Times New Roman", Times, serif',
                      }}
                    >
                      <tr height="21%" style={{padding:'0',borderBottom: 'none'}}>
                        <td style={{padding:'0',borderBottom: 'none',fontSize: '4mm',color:'#900020', fontWeight: 'bold',textAlign: 'center',verticalAlign: 'bottom',
                      }} colspan="2">
                          {el.hasCopy ? <span>Дублікат</span>:''}
                        </td>
                        <td
                          colspan="4"
                          align="center"
                          valign="middle"
                          style={{ fontSize: '3.1mm',fontWeight:"bold", color: '#00008B',padding:'0',borderBottom: 'none' }}
                        >
                          МІНІСТЕРСТВО
                          <br />
                          МОЛОДІ ТА СПОРТУ УКРАЇНИ
                          <br />
                          <font style={{color:'#0047AB',fontSize: '4.4mm'}}>
                            <strong>
                              П О С В І Д Ч Е Н Н Я {!el.hasCopy &&  <>№{' '}
                              <font color="#C04000">{el.certificateNumber}</font></>}
                            </strong>
                          </font>
                        </td>
                      </tr>
                      <tr height="19%" style={{padding:'0',borderBottom: 'none'}}>
                        <td style={{padding:'0',borderBottom: 'none'}} width="5%">
                          &nbsp;
                        </td>
                        <td
                          style={{padding:'0',borderBottom: 'none'}}
                          rowspan="3"
                          width="24%"
                          align="center"
                          valign="middle"
                        >
                          <img
                            src={el.application.photo?.url}
                            alt="photo"
                            width="100%"
                            height={'100%'}
                            style={{ boxShadow: '1px 1px 0px 0px gray',objectFit:'cover' }}
                          />
                        </td>
                        <td style={{padding:'0',borderBottom: 'none'}} width="3%">
                          &nbsp;
                        </td>
                        <td style={{padding:'0',borderBottom: 'none'}} width="3%">
                          &nbsp;
                        </td>
                        <td
                          width="60%"
                          align="center"
                          valign="middle"
                          style={{
                            background: 'URL("/Res/bluegrid.png")',
                            boxShadow: '1px 1px 0px 0px gray',
                            fontSize: '3.9mm',
                            color:'#00008B',
                            padding:'0',borderBottom: 'none'
                          }}
                        >
                          <strong>
                            {el.application.athleteLastName.toUpperCase()}
                            <br />
                            {el.application.athleteFirstName} {el.application.athleteSecondName}
                          </strong>
                        </td>
                        <td style={{padding:'0',borderBottom: 'none'}} width="5%">
                          &nbsp;
                        </td>
                      </tr>
                      <tr height="4%" style={{padding:'0',borderBottom: 'none'}}>
                        <td style={{padding:'0',borderBottom: 'none'}}>&nbsp;</td>
                        <td style={{padding:'0',borderBottom: 'none'}} colspan="4">
                          &nbsp;
                        </td>
                      </tr>
                      {/* <tr height='28%' style={{'padding':'0'}}><td style={{'padding':'0'}}>&nbsp;</td><td style={{'padding':'0'}} colspan='2'>&nbsp;</td><td style={{'padding':'0'}} align='center' valign='middle' style={{'background': 'URL("/Res/bluegrid.png")', boxShadow:'1px 1px 0px 0px gray', fontSize:'3.7mm', 'color':'#990000'}}>{this.props.currentApplication && this.props.currentApplication.athleteCategoryTitle.split(' ').length>3?<strong> МАЙСТЕР СПОРТУ УКРАЇНИ<br/>міжнародного класу<br/><font size='2.5mm' style={{lineHeight:'1.8'}}>з виду спорту:</font><br/>{this.props.currentApplication.sportTitle}</strong>:<strong> МАЙСТЕР СПОРТУ УКРАЇНИ <br /><font size='2.5mm' style={{lineHeight:'1.8'}}>з виду спорту:</font><br/>{this.props.currentApplication.sportTitle}</strong>}</td><td style={{'padding':'0'}}>&nbsp;</td></tr> */}
                      <tr height="28%" style={{padding:'0',borderBottom: 'none'}}>
                        <td style={{padding:'0',borderBottom: 'none'}}>&nbsp;</td>
                        <td style={{padding:'0',borderBottom: 'none'}} colspan="2">
                          &nbsp;
                        </td>
                        <td
                          align="center"
                          valign="middle"
                          style={{
                            background: 'URL("/Res/bluegrid.png")',
                            boxShadow: '1px 1px 0px 0px gray',
                            fontSize: '4.2mm',
                            color: '#900020',
                            fontWeight: 'bold',
                            padding:'0',borderBottom: 'none'
                          }}
                        >
                          <span style={{ textTransform: 'uppercase' }}>
                            {el.application.athleteCategoryTitle?.split('/')[0]}
                          </span>{' '}
                          <br />
                          <font size="2.5mm" style={{ lineHeight: '1.8' }}>
                            {' '}
                            <span style={{ fontWeight: 'normal',fontSize:'4.7mm' }}>{getPreposition(el.application?.sportTitleGenitive)} </span>
                          </font>
                          <br />
                          <span  style={{ fontWeight: 'normal',fontSize:'4.7mm' }}> {el.application?.sportTitleGenitive ||
                          el.application.sportTitle} {checkInvaFunc(el.application.sportCategoryId).length>0 &&<><br/>{checkInvaFunc(el.application.sportCategoryId)}</>}</span>
                        </td>
                        <td style={{padding:'0',borderBottom: 'none'}}>&nbsp;</td>
                      </tr>

                      <tr height="4%" style={{padding:'0',borderBottom: 'none'}}>
                        <td style={{padding:'0',borderBottom: 'none'}} colspan="6">
                          &nbsp;
                        </td>
                      </tr>
                      <tr height="19%" style={{padding:'0',borderBottom: 'none'}}>
                        <td style={{padding:'0',borderBottom: 'none'}}>&nbsp;</td>
                        <td
                          colspan="2"
                          align="center"
                          valign="middle"
                          style={{
                            background: 'URL("/Res/bluegrid.png")',
                            boxShadow: '1px 1px 0px 0px gray',
                            color: '#8B0000',
                            fontSize: '3.7mm',
                            padding:'0',borderBottom: 'none'
                          }}
                        >
                          <strong>
                            наказ №&nbsp;{el.orderNumber}
                            <br />
                            від&nbsp;
                            {new Date(el.assignDate).toLocaleString('uk-UA', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            })}
                            &nbsp;р.
                          </strong>
                        </td>
                        <td style={{padding:'0',borderBottom: 'none'}}>&nbsp;</td>
                        <td
                          valign="middle"
                          align="left"
                          style={{
                            background: 'URL("/Res/bluegrid.png")',
                            boxShadow: '1px 1px 0px 0px gray',
                            padding:'0',borderBottom: 'none'
                          }}
                        >
                          <table
                            width="100%"
                            height="100%"
                            cellspacing="0"
                            cellpadding="3px"
                            border="0"
                            style={{ fontSize: '3.8mm',
                            color:'#00008B',
                            padding:'0',borderBottom: 'none' }}
                          >
                            <tr style={{padding:'0',borderBottom: 'none'}} >
                              <td style={{padding:'0 0 6px',borderBottom: 'none',verticalAlign:'bottom'}} align="left">
                                {el.signer2Position?.split(' молоді та спорту України')[0]}
                              </td>
                              <td style={{padding:'0',borderBottom: 'none'}} width="30%">
                                &nbsp;
                              </td>
                              <td style={{padding:'0 0 6px',borderBottom: 'none', verticalAlign:'bottom', textAlign:'right'}}>
                                {el.signer2Name?.split('.')[0][0]+'.'+el.signer2Name?.split(' ')[1]}
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td style={{padding:'0',borderBottom: 'none'}}>&nbsp;</td>
                      </tr>
                      <tr height="5%" style={{padding:'0',borderBottom: 'none'}}>
                        <td style={{padding:'0',borderBottom: 'none'}} colspan="6">
                          &nbsp;
                        </td>
                      </tr>
                    </table>
                  </div>
                  <br />
                </div>
              );
            }
          })}
      </div>
    );
  }
}

export default Res;
