import axios from 'axios';

import constants from '../../constants/constant.json';
import { PAGE_SIZE_CERTIFICATES } from '../../constants/const';
import authHeader from './auth-header';

const getCertificateItem = (body) => {
  return axios
    .get(constants.API_URL + `/applications/${body.applicationId}/certificate`, {
      headers: authHeader(),
    })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};

const updateCertificateItem = (body) => {
  return axios
    .put(
      constants.API_URL + `/applications/${body.applicationId}/certificate`,
      { ...body },
      { headers: authHeader() },
    )
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};

const registerOrderNumbers = (body) => {
  return axios
    .put(
      constants.API_URL + `/certificates/order-number`,
      { ...body },
      { headers: authHeader() },
    )
    .then((response) =>response.data)
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};


const updateMultipleCertificate = (body) => {
  return axios
    .post(constants.API_URL + '/certificates/update', { ...body }, { headers: authHeader() })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};

const getMultipleCertificateList = (data) => {
  return axios
    .post(constants.API_URL + `/certificates/list?offset=0&limit=50`, data, {
      headers: authHeader(),
    })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};

const getCertificateList = async (data, page = 1) => {
  const urlParams = new URLSearchParams();
  const limit = PAGE_SIZE_CERTIFICATES;
  urlParams.append('offset', ((page - 1) * limit).toString());
  urlParams.append('limit', limit.toString());

  const sort = {
    name: 'assignDate',
    value: 'asc',
  };

  if (data) {
    if (typeof data === 'string') {
      urlParams.append('filters[orderNumber]', data);
    } else {
      urlParams.append('filters[assignDateExists]', '1');
      data
        .filter((item) => item.sortName)
        .forEach((item) => {
          sort.name = item.sortName;
          sort.value = item.sortValue;
        });

      data
        .filter((item) => item.name)
        .forEach((item) => {
          urlParams.set(`filters[${item.name}]`, item.value);
        });
    }
  }

  urlParams.append(`sort[${sort.name}]`, sort.value);

  return axios
    .get(constants.API_URL + '/certificates?' + urlParams.toString(), { headers: authHeader() })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};

const getAllCertificateList = async (data) => {
  const result = {
    items: [],
    total: 0,
    offset: 0,
  };
  const urlParams = new URLSearchParams();

  const limit = 100;
  urlParams.append('offset', '0');
  urlParams.append('limit', limit.toString());
  urlParams.append('sort[assignDate]', 'asc');
  urlParams.append('filters[assignDateFrom]', data.assignDateFrom);
  urlParams.append('filters[assignDateTo]', data.assignDateTo);
  urlParams.append('filters[assignDateExists]', '1');

  return new Promise(async (resolve, reject) => {
    try {
      const data = await fetchData();

      for (let i = 1; i <= data.total / data.limit; i++) {
        urlParams.set('offset', (i * limit).toString());
        await fetchData();
      }
    } catch (e) {
      console.log(e);
      reject(e);
    }

    async function fetchData() {
      const url = constants.API_URL + '/certificates?' + urlParams.toString();
      const { data } = await axios.get(url, { headers: authHeader() });
      result.items = [...result.items, ...data.items];
      result.total = data.total;
      result.offset = data.offset;

      if (result.items.length === data.total) {
        resolve(result);
      }

      return data;
    }
  });
};


const getCertificateRepList = async (data) => {
  const result = {
    items: [],
    total: 0,
    offset: 0,
  };
  const urlParams = new URLSearchParams();

  const limit = 100;
  urlParams.append('offset', '0');
  urlParams.append('limit', limit.toString());
  urlParams.append('sort[assignDate]', 'asc');
  urlParams.append('filters[assignDateFrom]', data.assignDateFrom);
  urlParams.append('filters[assignDateTo]', data.assignDateTo);
  urlParams.append('filters[assignDateExists]', '1');
  return axios.get(constants.API_URL + '/certificates/new-report?' + urlParams.toString(), { headers: authHeader() })
  .then((response) => response.data)
  .catch((e) => {
    console.log(e);
    return Promise.reject(e);
  });
};


const deleteCertificate = (applicationId) => {
  return axios
    .delete(constants.API_URL + `/applications/${applicationId}/certificate`, {
      headers: authHeader(),
    })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};

const disbandCertificate = (orderNumber) => {
  return axios
    .post(
      constants.API_URL + `/certificates/disband`,
      {
        orderNumber,
      },
      {
        headers: authHeader(),
      },
    )
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};

const editNumberCertificate = (orderNumber) => {
  return axios
    .post(
      constants.API_URL + `/test/certificate-number`,
      orderNumber,
      {
        headers: authHeader(),
      },
    )
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};

const getUniqueAvailableOrderNumbers = (page) => {
  const limit = PAGE_SIZE_CERTIFICATES;
  const offset = (page - 1) * limit;
  return axios
    .get(constants.API_URL + `/certificates/order-numbers?offset=${offset}&limit=${limit}`, {
      headers: authHeader(),
    })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};

const getCertificateArchUniqList = (page,search='') => {
  const limit = PAGE_SIZE_CERTIFICATES;
  let filter=''
  const offset = (page - 1) * limit;
  if(search.length>1){
    filter+='&filters[all]='+search;
  }
  return axios
    .get(constants.API_URL + `/certificates/order-number?offset=${offset}&limit=${limit}${filter}`, {
      headers: authHeader(),
    })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};



const downloadRoster = async (data) => {
  const urlParams = new URLSearchParams();
  const sort = {
    name: 'assignDate',
    value: 'asc',
  };
  if (data) {
    if (typeof data === 'string') {
      urlParams.append('filters[orderNumber]', data);
    } else {
      urlParams.append('filters[assignDateExists]', '1');
      data
        .filter((item) => item.sortName)
        .forEach((item) => {
          sort.name = item.sortName;
          sort.value = item.sortValue;
        });

      data
        .filter((item) => item.name)
        .forEach((item) => {
          urlParams.set(`filters[${item.name}]`, item.value);
        });
    }
  }
  urlParams.append(`sort[${sort.name}]`, sort.value);
  return axios
    .get(constants.API_URL + '/register/report-exel?' + urlParams.toString(), { headers: authHeader() })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};


export default {
  getCertificateItem,
  updateCertificateItem,
  getCertificateList,
  getAllCertificateList,
  deleteCertificate,
  disbandCertificate,
  getUniqueAvailableOrderNumbers,
  updateMultipleCertificate,
  getMultipleCertificateList,
  getCertificateRepList,
  getCertificateArchUniqList,
  registerOrderNumbers,
  downloadRoster,
  editNumberCertificate
};
